declare let fbq: Function;

export function tagManager(tagId: string) {
  const node1 = document.createElement("script");
  const node2 = document.createElement("script");
  const node3 = document.createElement("script");
  const noScriptNode = document.createElement("noscript");
  const noScript = `<iframe src="https://www.googletagmanager.com/ns.html?id=${JSON.parse(
    tagId
  )}"
	height="0" width="0" style="display:none;visibility:hidden"></iframe>`;

  const script1 = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
	new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
	j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
	'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
	})(window,document,'script','dataLayer',${tagId});`;

  const script2 = `
	window.dataLayer = window.dataLayer || [];
	function gtag(){dataLayer.push(arguments);}
	gtag('js', new Date());
	gtag('config', ${tagId});`;

  node1.async = true;
  node1.defer = true;
  node1.innerHTML = script1;

  node2.async = true;
  node2.defer = true;
  node2.innerHTML = script2;

  node3.src =
    "https://www.googletagmanager.com/gtag/js?id=" + JSON.parse(tagId);
  document.head.appendChild(node1);
  document.head.appendChild(node3);
  document.head.appendChild(node2);
  noScriptNode.innerHTML = noScript;
  document.body.prepend(noScriptNode);
}
