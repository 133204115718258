import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { MenuItem, CartItem, AppEventType, TruckInfo } from "../models";
import { CartService } from "../cart.service";
import { Cart } from "../cart";
import { ExtraCategoryAlertComponent } from "../extra-category-alert/extra-category-alert.component";
import * as _ from "lodash";
import { UtilsService } from "../utils.service";
import { ApiService } from "../api.service";
import { GoogleTagManagerService } from "../google-tag-manager.service";
@Component({
  selector: "app-menu-item-card",
  templateUrl: "./menu-item-with-controls-card.component.html",
  styleUrls: ["./menu-item-with-controls-card.component.scss"],
})
export class MenuItemWithControlsCardComponent {
  @Input() item: MenuItem;
  @Input() checkExtraCategory: Boolean = false;
  @Input() extraChargeAlert: ExtraCategoryAlertComponent;
  cart: Cart;
  truck: TruckInfo;
  constructor(
    private router: Router,
    private srvCart: CartService,
    private utils: UtilsService,
    private api: ApiService,
    private gtagService: GoogleTagManagerService
  ) {
    this.cart = srvCart.getCart();
    this.api.viewTruck().subscribe((truck) => {
      this.truck = truck.truck;
    });
  }
  onItemClickClick(cat: any) {
    if (cat.subCategories && cat.subCategories.length)
      this.router.navigate(["/sub-category/" + cat.id]);
    else
      this.router.navigate(["/categories/" + cat.categoryInfo.id + "/items"]);
  }

  checkOutOfStock(item) {
    return this.srvCart.isOutOfStock(item);
  }
  addItem(item: MenuItem) {
    this.checkExtraCartCat(item);
    var found = _.find(
      this.cart.cartItems,
      (c) => c.$item.id == item.itemInfo.id
    );
    let oldQty = 0;
    if (found) oldQty = found.qty;
    if (!this.srvCart.validateItemToAdd(item, oldQty, 1, item.itemInfo.price))
      return;

    if (found) {
      found.qty += 1;
    } else {
      this.srvCart.addItem(
        _.assignIn(new CartItem(), {
          $item: item.itemInfo,
          extras: [],
          qty: 1,
          notes: "",
        })
      );
    }
    item.qty += 1;
    this.utils.fbTrack("AddToCart", {
      currency: "KWD",
      price: this.item.itemInfo.price,
      value: this.item.itemInfo.price,
      item_id: this.item.itemInfo.id,
      content_type: "product",
      content_ids: this.item.itemInfo.id,
    });

    this.utils.ttqTrack("AddToCart", {
      content_id: this.item.itemInfo.id,
      content_type: "product",
      content_name: this.item.itemInfo.title.en,
      quantity: this.item.qty,
      price: this.item.itemInfo.price,
      value: this.item.itemInfo.price,
      currency: "KWD",
    });
    if (item.qty > 1) {
      this.gtagService.addToCartEventEmitter(
        {
          id: item.itemInfo?.id,
          name: item.itemInfo?.title?.en,
          category: "Products",
          price: item.itemInfo?.price,
          quantity: item.qty,
          brand: item.itemInfo?.truckName?.en
            ? item.itemInfo?.truckName?.en
            : item.itemInfo?.truckName?.ar
            ? item.itemInfo?.truckName?.ar
            : "broker",
        },
        "add"
      );
    }
    this.utils.snapchatTrack("ADD_CART");
    this.api.trackEvent(AppEventType.AddToCart, item.itemInfo.id, {
      qty: item.qty,
    });
    this.srvCart.saveCart();
  }
  isAddon(item: MenuItem): boolean {
    if (!(item.extras.length > 0)) {
      return false;
    }

    let filter = item.extras.filter((item) => item.extraCategory.minQty > 0);

    return filter && filter?.length > 0;
  }
  removeItem(item: MenuItem) {
    var found = _.find(
      this.cart.cartItems,
      (c) => c.$item.id == item.itemInfo.id
    );
    if (found) {
      found.qty -= 1;
      item.qty -= 1;
      if (found.qty == 0) {
        this.srvCart.removeCartItem(found);
      }
    }
    this.srvCart.saveCart();
  }
  checkExtraCartCat(item) {
    let _self = this;
    let category = this.srvCart.getCart().baseCategory(item.itemInfo.id);
    if (this.cart.cartItems.length != 0) {
      let foundCat = _.find(
        this.cart.cartItems,
        (ci) => this.getCatId(ci.$item.id) == category.categoryInfo.id
      );
      if (
        !foundCat &&
        ((category.categoryInfo.extraDeliveryCharge &&
          category.categoryInfo.extraDeliveryCharge != 0) ||
          (category.categoryInfo.extraDeliveryTimeMinutes &&
            category.categoryInfo.extraDeliveryTimeMinutes != 0))
      )
        setTimeout(
          () =>
            this.extraChargeAlert &&
            this.extraChargeAlert.selectExtraCatAlert(() => {}, category)
        );
    }
  }

  getCatId(itemId) {
    var baseCat = this.cart.baseCategory(itemId);
    if (baseCat) return baseCat.categoryInfo.id;
  }

  openItemDetails(item: MenuItem) {
    this.router.navigate(["/item/" + item?.itemInfo.id]);
  }
}
